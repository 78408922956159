import React, { Component, Fragment } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    let Data = [
      {
        suffix: "K",
        countNum: 45,
        countTitle: "Clients leads",
      },
      {
        suffix: "K",
        countNum: 18,
        countTitle: "Clients online-meetings generated",
      },
      {
        prefix: "$",
        suffix: "M",
        countNum: 8,
        countTitle: "Client revenue generated",
      },
      {
        prefix: "$",
        suffix: "M",
        countNum: 32,
        countTitle: "Client pipeline generated",
      },
    ];

    return (
      <Fragment>
        <div className="row">
          {Data.map((value, index) => (
            <div className="counterup_style--1 col-lg-3 col-md-3 col-sm-6 col-6" key={index}>
              <h5 className="counter">
                {value.prefix}
                <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                  <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                </VisibilitySensor>
                {value.suffix}
              </h5>

              <p className="description">{value.countTitle}</p>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default CounterOne;
