import React, { Component } from "react";

const ServiceList = [
  {
    title: "LinkedIn Outreach + Cold Emailing Service to fix Appointments",
    items: [
      "Meticulous lead validation ensures the accuracy and currency of your leads.",
      "Specialized prospect research tailored to identify top-tier prospects",
      "Individualized lead targeting to facilitate customized outreach efforts.",
      "Holistic campaign management to streamline your outreach initiatives.",
      "Limitless copy revisions to ensure impactful messaging.",
      "Regular weekly reports to monitor and track progress.",
      "Consistent weekly reports to oversee and evaluate progress.",
      "Utilizing multiple channels for extensive reach and impactful engagement.",
      "Results oriented towards maximizing ROI and ensuring business success.",
      "We take care of everything, providing a 100% hassle-free experience.",
    ],
  },
  {
    title: "Fravo Monthly KPI",
    items: [
      "500 Companies targeted",
      "2000 Prospects identified",
      "Daily invitations sent: 65+ for 20 days, totaling 1300+ invitations per month",
      "Minimum 25%+ connection rate achieved, resulting in 325+ connections",
      "Total messages sent: 6 (3 messages within the first 15 days of connection, followed by a 45-day gap, then 3 additional follow-up messages within the next 15 days)",
      "Cold emails sent to 2000 Prospects with 4 follow-ups, amounting to 6000+ total emails",
      "Over 300 In-mails sent to open LinkedIn profiles of those who haven't accepted the invitation",
    ],
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a>
                <div className="service service__style--2 w-100">
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>
                      <ul className="list-">
                        {val.items.map((item) => (
                          <li className="small pb-3" style={{ lineHeight: 1.3 }}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
