import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const reviews = [
  {
    text: "Fravo has been instrumental in boosting our B2B lead generation efforts on LinkedIn. Their team's expertise and personalized approach have resulted in a significant increase in high-quality leads for our business. We highly recommend Fravo for any company looking to leverage LinkedIn for effective lead generation.",
    author: {
      name: "Jennifer Anderson",
      pos: "CEO of a technology company",
      img: "/assets/images/client/testimonial-1.jpg",
    },
  },
  {
    text: "Working with Fravo has been a game-changer for our B2B marketing strategy. Their in-depth knowledge of LinkedIn and their ability to target the right audience has helped us connect with valuable prospects. The team at Fravo is responsive, professional, and consistently delivers impressive results. We're thrilled with the increased lead generation and the impact it has had on our business.",
    author: {
      name: "Michael Johnson",
      pos: "Chief Financial Officer (CFO) of a multinational corporation",
      img: "/assets/images/client/testimonial-2.jpg",
    },
  },
  {
    text: "Fravo exceeded our expectations in every way. They took the time to understand our unique business needs and crafted a LinkedIn lead generation campaign that precisely targeted our ideal audience. The quality of leads we received was exceptional, and their ongoing support and optimization ensured maximum ROI. Fravo is a trusted partner that we highly recommend for B2B lead generation.",
    author: {
      name: "Emily Roberts",
      pos: "Chief Marketing Officer (CMO) of a consumer goods company",
      img: "/assets/images/client/testimonial-3.jpg",
    },
  },
  {
    text: "Fravo's lead generation services have been a game-changer for our sales team. Their team's expertise in leveraging LinkedIn to generate qualified leads is unmatched. We've seen a significant increase in the number of high-quality leads, resulting in a boost in our sales pipeline. Fravo's professionalism, strategic approach, and outstanding results make them our go-to choice for B2B lead generation.",
    author: {
      name: "David Thompson",
      pos: "Chief Operating Officer (COO) of a manufacturing company",
      img: "/assets/images/client/testimonial-4.jpg",
    },
  },
  {
    text: "Fravo has been a valuable partner for our B2B company. Their tailored approach and in-depth understanding of LinkedIn's platform have helped us reach our target audience effectively. We've experienced a substantial increase in lead generation, and their regular reporting and insights have been invaluable in measuring the success of our campaigns. Fravo is a reliable and results-driven LinkedIn lead generation company.",
    author: {
      name: "Sarah Davis",
      pos: "Vice President of Human Resources at a financial services firm",
      img: "/assets/images/client/testimonial-5.jpg",
    },
  },
];
class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              {reviews.map((r) => (
                <TabPanel>
                  <div className="rn-testimonial-content text-center">
                    <div className="inner">
                      <p>{r.text}</p>
                    </div>
                    <div className="author-info">
                      <h6>
                        <span>{r.author.name}</span> - {r.author.pos}
                      </h6>
                    </div>
                  </div>
                </TabPanel>
              ))}

              <TabList className="testimonial-thumb-wrapper">
                {reviews.map((r) => (
                  <Tab>
                    <div className="testimonial-thumbnai">
                      <div className="thumb">
                        <img src={r.author.img} alt="Testimonial Images" />
                      </div>
                    </div>
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
