import React, { Fragment, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const Faq = () => {
  const [opened, setOpened] = useState(0);
  const faqs = [
    {
      qus: "What is Cold Outreach on LinkedIn?",
      ans: "Cold Outreach on LinkedIn is the process of connecting with potential customers, partners, or other contacts through the platform. It can be done through InMail messages, connection requests, or even direct messages.The key to success with cold outreach on LinkedIn is to personalize your approach and offer value upfront. Simply sending a generic message or request is unlikely to get you very far. Instead, take the time to research your potential contacts and craft a message that will resonate with them.If done correctly, cold outreach on LinkedIn can be an extremely effective way to build relationships and grow your business. However, it's important to keep in mind that not everyone is going to be receptive to your overtures. Don't take it personally if you don't get a response from everyone you reach out to - just keep trying and eventually you'll find the people who are interested in what you have to offer.",
    },
    {
      qus: "What are LinkedIn Prospecting Messages?",
      ans: "If you're not familiar with the term, \"LinkedIn prospecting messages\" refers to the process of reaching out to potential customers or clients through LinkedIn. This can be done by sending a direct message, connecting with someone who has already made a connection with your target, or by joining groups and participating in discussions.The key to successful LinkedIn prospecting messages is to come across as genuine and helpful, rather than salesy or spammy. If you can establish a connection with someone and offer value upfront, you'll be much more likely to convert them into a customer or client down the road.Here are a few tips for crafting effective LinkedIn prospecting messages:- Keep it short and sweet: No one wants to read a long, rambling message. Get to the point quickly and be concise.- Personalize your message: Generic messages are a turn-off. Take the time to personalize your message for each person you're reaching out to.- Offer value: What can you offer the person you're reaching out to that will be of value to them? This could be information, resources, or even just a helpful introduction.- Be genuine: Don't try to hard sell someone from the get-go. Build a rapport and relationship first, and the sales will come naturally down the road.",
    },
    {
      qus: "What is LinkedIn Outreach?",
      ans: "LinkedIn outreach is the process of connecting with other professionals on LinkedIn in order to build relationships and expand your network. This can be done by sending messages, invitations to connect, or InMails. LinkedIn outreach can be an effective way to build relationships with potential customers, clients, or employers. It can also be a great way to find new business opportunities or learn about new trends in your industry.When you're doing LinkedIn outreach, it's important to be professional and polite. You should also make sure that you're providing value to the person you're reaching out to. For example, if you're a salesperson, you might offer to send information about your product or service. If you're a job seeker, you might offer to help with research or connect the person with someone you know.LinkedIn outreach can be a great way to build relationships and expand your network. Just make sure that you're being professional and providing value when you reach out to others.",
    },
    {
      qus: "Why do LinkedIn profiles get restricted?",
      ans: "LinkedIn profiles can get restricted for many reasons, but here are a list of a few:- The profile is manually flagged by several people on LinkedIn as spam.- Too many outstanding pending invites (but usually this leads to not being able to send invites not a profile restriction)- Sending too many invites in a given 24hr timeframe (we recommend 50-100 invites per day, even if you are using a paid version of LinkedIn).",
    },
    {
      qus: "How many connections are made per day?",
      ans: "This depends on the quality of the LinkedIn profile. If the LinkedIn profile has been around for a long time (5+ years) and has many connections (1000+), then we typically send 100-200 invites per day. But if a LinkedIn profile is anything short of that we don’t recommend messaging over 100 invites per day, typically we will send 50-100 social outreach invites per day on average. It is also important to make sure that you don’t have too many pending invites, typically we recommend removing pending invites once you exceed more than 1500 pending invites.",
    },
    {
      qus: "Is LinkedIn too crowded to be effective for lead generation?",
      ans: "LinkedIn is not too crowded, particularly if you are going after a niche job title because they wil not be receiving thousands of LinkedIn invites and messages per day. Yes, the CEO of Uber will most likely not respond to your LinkedIn request, but the Head of Logistics at a growing transporation company might love the attention and respond as soon as they see your message. So in short, the more targeted and niche, the better. That’s not to say we haven’t booked meetings through LinkedIn with big companies or executives either, but in general it can be less effective as you target the same prospects hundreds of other companies have tried to target as well.",
    },
    {
      qus: "How effective is LinkedIn?",
      ans: "This always depends on the industry, but when comparing LinkedIn to email using our entire client base, the channel itself is just as effective. In fact, LinkedIn has been the most effective channel for some of our clients. The biggest variations occur when comparing industries or target personas. For specific industries such as IT or Marketing, LinkedIn has been proven to be significantly more effective than email. But, when reaching out to small businesses like restaurants, or target personas that tend to not care about their professional online presense, email and cold calling can be far superior. It all comes down to your market, and we’d be happy to discuss this with you to see what makes the most sense for your business.",
    },
    {
      qus: "What is the benefit of prospecting on LinkedIn?",
      ans: "The benefit to prospecting on a social platform like LinkedIn is that it can expand your reach and it is also the preferred communication channel for a percentage of your target market. Firstly, it expands your reach because you will not be able to find or purchase the email address of everyone in your target market, but you can always reach out to them on LinkedIn as most business professionals maintain a profile on LinkedIn, as LinkedIn acts as many people’s resume. Secondly, it is the preferred communication channel for 20-30% or your target list, as many people enjoy the fluidity of a LinkedIn chat compared to email or LinkedIn, this is generally because you are able to learn more about the person on the other end of the chat by viewing their LinkedIn profile and you are able to chat in real-time and see when a person is online aka. communication is faster in general.",
    },
    {
      qus: "How is LinkedIn used to generate B2B sales leads?",
      ans: "LinkedIn is a great resource for sales and marketing. There are many different methods to generating leads on LinkedIn, but our primary tactic is to send invites to top prospects with a custom invitation message. Then once the prospect accepts the invite, we thank them and send them a custom message describing how our products can make their life easier or make them more money. The key to social media outreach is to be informal (aka. conversational) in tone. If a prospect does not reply, we send them a follow up message. This is what we call a LinkedIn Messaging Cadence.",
    },
  ];
  return (
    <Fragment>
      <div className="section-title-default">
        <h2 className="text-center">FAQ</h2>
        <div>
          {faqs.map((f, i) => (
            <div className="bg-white shadow rounded my-3 p-3">
              <div className="d-flex justify-content-between" role="button" onClick={() => setOpened(i)}>
                <h5 className="m-0">{f.qus}</h5>
                <h5 className="m-0 ">
                  {opened === i ? <FiChevronUp className="p-0" /> : <FiChevronDown className="p-0" />}
                </h5>
              </div>
              {opened === i && (
                <div className="pt-4">
                  <p>{f.ans}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Faq;
