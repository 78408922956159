import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiTarget } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiTarget />,
    title: "Executive-Level Targeting",
    description:
      "We’re not afraid to go after the top executives in each organization, so you can speak directly with decision makers.",
  },
  {
    icon: <FiLayers />,
    title: "Unique Copywriting",
    description:
      "We create messages that are unique to your company, so you’re not sending out the same, boring messages everyone else does.",
  },
  {
    icon: <FiUsers />,
    title: "Multichannel Outreach",
    description:
      "We use three channels (email, LinkedIn, and phone) to stand out from the crowd of other outbound messages in your prospect’s inbox.",
  },
  {
    icon: <FiMonitor />,
    title: "Customized Responses",
    description: "We personally respond to each prospect to build rapport, making the handoff warmer once you jump in.",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a>
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
