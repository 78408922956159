import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { slickDot } from "../page-demo/script";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import { FiCheck, FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import ServiceListTwo from "../elements/service/ServiceListTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
// import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import Faq from "../elements/Faq";
import Calendly from "../elements/Calendly";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "The #1 B2B Lead Generation & Appointment Setting Agency",
    description: `Find Qualified Clients for your Business. Close more deals with ROI-focused Lead Generation`,
    buttonText: "CLAIM YOUR FREE 30-MINUTE STRATEGY SESSION NOW",
    buttonLink: "/contact",
  },
];

const list = [
  {
    image: "image-1",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-2",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
];

class CreativeLanding extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}

  render() {
    var namesItemOne = [
      "Our services include automated follow-up messages for unresponsive businesses.",
      "To enhance response rates, we provide four or more subsequent follow-up messages.",
      "We specialize in personalized profile optimization to reinforce brand identity and attract potential clients.",
      "Suggestions encompass incorporating keywords and showcasing recent accomplishments.",
      "Strengthened profiles amplify the impact of personalized messaging.",
      "Our comprehensive messaging system encompasses profile personalization.",
      "Fravo extends its services to those overwhelmed by simultaneous operations or seeking new business opportunities in lead generation.",
      "Our services enable businesses to leverage the emerging LinkedIn trend in company branding and marketing.",
      "We offer premium solutions to assist businesses in establishing a stronger reputation.",
    ];
    const PostList = BlogContent.slice(0, 5);

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector(".submenu").classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        <Helmet />
        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images" />
                  <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images" />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={["home", "service", "about", "portfolio", "team", "testimonial", "blog", "contact"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  {/* <li className="has-droupdown">
                    <a href="#home">Home</a>
                    <ul className="submenu">
                      <li className="has-droupdown">
                        <a href="#">Home</a>
                        <ul className="submenu">
                          <li>
                            <a href="#">Submenu</a>
                          </li>
                          <li>
                            <a href="#">Submenu</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#">Home</a>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#service2">Linkedin Lead Gen</a>
                  </li>
                  <li>
                    <a href="#personalBranding">Personal Branding</a>
                  </li>
                  <li>
                    <a href="#Company">Company</a>
                  </li>
                  <li>
                    <a href="#FAQs">FAQs</a>
                  </li>
                  <li>
                    <a href="#contact">Contact Us</a>
                  </li>
                </Scrollspy>
              </nav>
              <div className="header-btn">
                <a className="rn-btn" href="#calendly">
                  <span>Book A Call</span>
                </a>
              </div>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span onClick={this.menuTrigger} className="menutrigger text-white">
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-activation slider-creative-agency" id="home">
          <div className="bg_image bg_image--26" data-black-overlay="6">
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? <h1 className="theme-gradient text-light">{value.title}</h1> : ""}
                        {value.description ? <p className="description">{value.description}</p> : ""}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a className="rn-button-style--2 btn-primary-color btn-solid" href="#calendly">
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}

                        <p className="description mt--50">
                          <small>
                            Within the initial week , Fravo assists in audience targeting, establishing the framework
                            for a LinkedIn sales funnel, crafting tailored cold messages, and providing sales team
                            training.
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Single Area */}
        <div className="about-area ptb--120 bg_color--" id="about">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src="/assets/images/service/service-01.png" alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">Artificial intelligence with expert supervision</h2>
                      <p className="description">
                        FRAVO is an agency that works side-by-side with AI technology. This doesn’t mean that every task
                        is performed by a computer, but rather that it helps improve and complement what was already
                        carefully hand-crafted by our team of marketers, designers, and copywriters.
                      </p>
                      <a className="rn-button-style--2 btn-primary-color btn-solid" href="#calendly">
                        CLAIM YOUR FREE 30-MINUTE STRATEGY SESSION NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Single Area */}

        {/* Start Service2 Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="service2">
          <div className="container">
            <div className="section-title text-center">
              <h2 className="title">Fravo Mega Services</h2>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceListTwo item="6" column="col-lg-6 col-md-6 col-sm-6 col-12 text-left" />
              </div>
            </div>
          </div>
        </div>
        {/* End Service2 Area  */}
        {/* Start Personal Branding Area */}
        <div className="about-area ptb--120 bg_color--" id="personalBranding">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">PERSONAL BRANDING</h2>
                      <p className="description">
                        Personal branding gets a bad rap. But it shouldn’t. Building something that wins great clients,
                        attracts employees and accelerates your businesses’ growth is awesome.
                      </p>
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> linkedin profile optimisation
                        </li>
                        <li>
                          <FiCheck /> Social Media Posts
                        </li>
                        <li>
                          <FiCheck /> Hashtags & Keyword Research
                        </li>
                        <li>
                          <FiCheck /> Post boosting by sharing in different relevant groups
                        </li>
                        <li>
                          <FiCheck /> Static images or Video based on post criteria
                        </li>
                        <li>
                          <FiCheck /> Unlimited Copy Edits
                        </li>
                        <li>
                          <FiCheck /> Target market analysis
                        </li>
                        <li>
                          <FiCheck /> Monthly content planning
                        </li>
                        <li>
                          <FiCheck /> Hashtag & engagement strategy
                        </li>
                        <li>
                          <FiCheck /> Boosting posts & Generating Comments
                        </li>
                      </ul>
                      <a className="rn-button-style--2 btn-primary-color btn-solid" href="#calendly">
                        CLAIM YOUR FREE 30-MINUTE STRATEGY SESSION NOW
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src="/assets/images/about/linkedin.png" alt="About Images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Personal Branding Area */}

        {/* Start Why Us Area  */}
        <div className="about-area ptb--120 bg_color--5" id="about">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src="/assets/images/about/about-4.png" alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h3 style={{ lineHeight: 1.3 }}>Why Fravo Linkedin Lead Generation Service</h3>
                      <ul className="list-style--1">
                        {namesItemOne.map((name, index) => {
                          return (
                            <li key={index}>
                              <FiCheck /> {name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <a className="rn-button-style--2 btn-primary-color btn-solid" href="#calendly">
                      CLAIM YOUR FREE 30-MINUTE STRATEGY SESSION NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Why Us Area  */}

        {/* Start Service Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="service">
          <div className="container">
            <div className="section-title text-center">
              <h2>Need More Convincing to Hire Fravo ?</h2>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList item="6" column="col-lg-6 col-md-6 col-sm-6 col-12 text-left" />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--5" id="about">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src="/assets/images/about/about-3.png" alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">About Fravo</h2>
                      <p className="description">
                        Fravo is a leading B2B LinkedIn lead generation company specializing in helping businesses
                        connect with their target audience. With a wealth of expertise in digital marketing and LinkedIn
                        strategies, Fravo employs advanced techniques to identify, engage, and convert high-quality
                        leads. Fravo tailored approach ensures that clients receive personalized campaigns that align
                        with their specific goals and objectives.
                      </p>
                      <a className="rn-button-style--2 btn-primary-color btn-solid" href="#calendly">
                        CLAIM YOUR FREE 30-MINUTE STRATEGY SESSION NOW
                      </a>
                    </div>
                    {/* <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Who we are</h3>
                          <p>
                            There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Who we are</h3>
                          <p>
                            There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        {/* Start Portfolio Area */}
        {/* <div className="portfolio-area pt--120 pb--140 bg_color--1" id="portfolio">
          <div className="rn-slick-dot">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                    <h2 className="title">Our Portfolio</h2>
                    <p>
                      There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                      alteration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="slick-space-gutter--15 slickdot--20">
                    <Slider {...slickDot}>
                      {list.map((value, index) => (
                        <div className="portfolio" key={index}>
                          <div className="thumbnail-inner">
                            <div className={`thumbnail ${value.image}`}></div>
                            <div className={`bg-blr-image ${value.image}`}></div>
                          </div>
                          <div className="content">
                            <div className="inner">
                              <p>{value.category}</p>
                              <h4>
                                <a>{value.title}</a>
                              </h4>
                              <div className="portfolio-button">
                                <a className="rn-btn" href="/portfolio-details">
                                  Case Study
                                </a> 
                              </div>
                            </div>
                          </div>
                          <Link className="link-overlay" to="/portfolio-details"></Link>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Portfolio Area */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pt--140 p pb--110 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
          <div className="text-center mt-5 pt-5">
            <a className="rn-button-style--2 btn-primary-color btn-solid" href="#calendly">
              CLAIM YOUR FREE 30-MINUTE STRATEGY SESSION NOW
            </a>
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Team Area  */}
        {/* <div className="rn-team-area ptb--120 bg_color--1" id="team">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                  <h2 className="title">Skilled Team</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
            </div>
          </div>
        </div> */}
        {/* End Team Area  */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120" id="testimonial">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Blog Area */}
        <div className="rn-blog-area pt--120 pb--140 bg_color--1" id="blog">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6">
                <div className="section-title text-left">
                  <h2>Latest News</h2>
                  <p>Expand your b2b Linkedin Lead Generation Knowledge</p>
                </div>
              </div>
            </div>
            <div className="row mt--55 mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
              <div className="col-lg-12">
                <Slider {...slickDot}>
                  {PostList.map((value, i) => (
                    <div className="blog blog-style--1" key={i}>
                      <div className="thumbnail">
                        <a>
                          <img src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images" />
                        </a>
                      </div>
                      <div className="content">
                        <p className="blogtype">{value.category}</p>
                        <h4 className="title pb-5">
                          <a>{value.title}</a>
                        </h4>
                        {/* <div className="blog-btn">
                          <a className="rn-btn text-white" href="/blog-details">
                            Read More
                          </a>
                        </div> */}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Area */}

        <div className="container ptb--50" id="FAQs">
          <Faq />
        </div>

        {/* Start Contact Us */}
        <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
          <Contact />
        </div>
        {/* End Contact Us */}

        {/* Start Brand Area */}
        <div className="rn-brand-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        <div id="calendly">
          <Calendly />
        </div>

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default CreativeLanding;
